import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowDown } from "react-icons/bs";
import { anonimise, chat, generateImage } from "../../hooks/apis/aiSafe";
import { useTranslation } from "react-i18next";
import "./MainAiSafe.scss";
import ConversationEntry from "./main/ConversationEntry";
import PrivacySettings from "./main/PrivacySettings";
import { LuSendHorizonal } from "react-icons/lu";

import img1 from "../../images/setting.svg";
import img2 from "../../images/aiSafe.svg";
import { GoPaperclip } from "react-icons/go";
import { serverAxios } from "../../utils/AxiosUtils";
import PromptHighlighter from "./textHighliter";

const MainAiSafe = () => {
  const { t } = useTranslation("aisafe");
  const navigate = useNavigate();
  const { id } = useParams();
  const [privacySettings, setPrivacySettings] = useState({
    Names: true,
    Emails: true,
    Phone: false,
  });
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState({
    originalPrompt: "",
    anonymizedPrompt: "",
    chatResponse: "",
  });
  const [error, setError] = useState("");
  const [hasLanded, setHasLanded] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [idchat, setIdChat] = useState(null);
  const [piiDetection, setPiiDetection] = useState({
    Names: [],
    Emails: [],
    Phones: [],
  });
  const [modelLanguage, setModelLanguage] = useState(() => {
    return localStorage.getItem("modelLanguage") || "eng"; // Default to 'eng'
  });

  const [isAnonymized, setIsAnonymized] = useState(false);
  const [isEditing, setIsEditing] = useState(1);

  const [isImageMode, setIsImageMode] = useState(false);
  const [imageHistory, setImageHistory] = useState([]);

  const conversationHistoryRef = useRef(null);
  const textareaRef = useRef(null);
  const sidebarRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCheckboxChange = (key) => {
    setPrivacySettings((prevSettings) => ({
      ...prevSettings,
      [key]: !prevSettings[key],
    }));
  };
  const handleLanguageChange = (language) => {
    setModelLanguage(language);
    localStorage.setItem("modelLanguage", language);
  };
  useEffect(() => {
    const storedLanguage = localStorage.getItem("modelLanguage");
    if (storedLanguage) {
      setModelLanguage(storedLanguage);
    }
  }, []);

  const handleChat = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;

    const tempEntry = {
      originalPrompt: trimmedPrompt,
      anonymizedPrompt: "Loading...",
      chatResponse: "Loading...",
    };

    // Step 1: Add the temporary entry to the conversation history
    setConversationHistory(tempEntry);
    setLoading(true);
    setError("");
    setHasLanded(false);
    setPrompt("");

    try {
      const choices = Object.keys(privacySettings).filter(
        (key) => privacySettings[key]
      );

      const new_chat = id ? id : "True";
      const new_chat2 = idchat ? idchat : "False";
      const model = modelLanguage;
      const anonymized = "False";

      const chatResponse = await chat(
        trimmedPrompt,
        choices,
        new_chat,
        new_chat2,
        "llama",
        anonymized,
        model
      );
      const { data, message, response_code, success } = chatResponse;

      if (response_code !== 200 || !success) {
        throw new Error(message);
      }

      const newEntry = {
        originalPrompt: prompt,
        anonymizedPrompt: data[2] || "No anonymized prompt available",
        chatResponse: data[4] || "No response available",
      };
      setConversationHistory(newEntry);

      // Update the URL with the id from data[0]
      const newId = data[0];
      navigate(`/ai-safe/c/${newId}`);

      setLoading(false);
    } catch (error) {
      const newEntry = {
        originalPrompt: prompt,
        anonymizedPrompt: "Somthing Wrong, Please try gain later.",
        chatResponse: "Somthing wrong, Please try gain later.",
      };
      setConversationHistory(newEntry);
      setError(
        "An error occurred: " +
          (error.response?.status === 500
            ? "Internal Server Error"
            : error.message)
      );
      setLoading(false);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [prompt]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = conversationHistoryRef.current.scrollHeight;
      const scrollTop = conversationHistoryRef.current.scrollTop;
      const clientHeight = conversationHistoryRef.current.clientHeight;

      if (scrollHeight - scrollTop > clientHeight + 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    if (conversationHistoryRef.current) {
      conversationHistoryRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (conversationHistoryRef.current) {
        conversationHistoryRef.current.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, []);

  useEffect(() => {
    if (conversationHistoryRef.current) {
      conversationHistoryRef.current.scrollTop =
        conversationHistoryRef.current.scrollHeight;
    }
  }, [conversationHistory]);

  const scrollToBottom = () => {
    if (conversationHistoryRef.current) {
      conversationHistoryRef.current.scrollTop =
        conversationHistoryRef.current.scrollHeight;
    }
  };

  const show = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest(".button_ai_safe_main2")
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // console.log("File selected:", file);

      setIsUploading(true); // Start uploading spinner

      // Convert the file into binary (ArrayBuffer)
      const arrayBuffer = await file.arrayBuffer();

      try {
        // Send the file as binary data to the backend
        const response = await serverAxios.aiSafeAxios.post(
          "/upload",
          arrayBuffer,
          {
            headers: {
              "Content-Type": "application/octet-stream",
              "File-Name": `${file.name}`,
              "File-Type": `${file.type}`,
            },
          }
        );

        if (response.data.success) {
          // console.log("File uploaded successfully:", response.data);
          // console.log("hello", response.data.data);

          // Once the file is uploaded, show its name in the textarea as ChatGPT would
          setPrompt((prev) => `${prev}\n[File uploaded: ${file.name}]`);
          setIdChat(response.data.data);
          setShowFileUploadPopup(false);
        } else {
          throw new Error(response.data.message || "File upload failed");
        }
      } catch (error) {
        console.error("Error in file upload:", error.message);
        setError("File upload failed. Please try again.");
      } finally {
        setIsUploading(false);
      }
    }
  };
  let tp;
  const handleAnonymize = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;
    const tempEntry = {
      originalPrompt: trimmedPrompt,
      anonymizedPrompt: "Wait for Anonymization",
      chatResponse: "AI is Thinking...",
    };
    setConversationHistory(tempEntry);
    setLoading(true);
    setHasLanded(false);

    try {
      const choices = Object.keys(privacySettings).filter(
        (key) => privacySettings[key]
      );
      //   {
      //     "lang":"eng",
      //     "prompt":"then sumit wants to meet his friend akshita",
      //     "choices":["Names","Emails","Phone"],
      //     "model":"llama",
      //     "new_chat":"9eef9751-c84b-507f-a69e-36692644c1c2",
      //     "doc":"False",
      //     "anonymized":"False"
      // }
      // prompt, choices, model, lang, doc, anonymized;
      const response = await anonimise(
        trimmedPrompt,
        choices,
        "llama",
        modelLanguage,
        "False",
        "False"
      );

      setPrompt(response[0]);
      tp = trimmedPrompt;

      const tempEntry = {
        originalPrompt: trimmedPrompt,
        anonymizedPrompt: response[0],
        chatResponse: "Waiting for chat...",
      };
      const piiDetect = {
        Names: response[1]?.Names ?? [],
        Phones: response[1]?.Phone ?? [],
        Emails: response[1]?.Emails ?? [],
      };
      setPiiDetection(piiDetect);

      setConversationHistory(tempEntry);
      setIsAnonymized(true);
    } catch (error) {
      setError("An error occurred during anonymization: " + error.message);
      const tempEntry = {
        originalPrompt: trimmedPrompt,
        anonymizedPrompt:
          "Somthing went wrong in anonymization, Please try gain later",
        chatResponse: "",
      };
      setConversationHistory(tempEntry);
    } finally {
      setLoading(false);
    }
  };

  const handleIsChatAnonymized = () => {
    if (isEditing === 1) {
      setIsEditing(0);
    } else {
      setIsEditing(1);
    }
  };

  const handleSubmitChat = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;

    setIsSubmitting(true);
    setPrompt("");

    try {
      if (isImageMode) {
        await handleImageGeneration();
      }
      if (!isAnonymized && !isImageMode) {
        await handleAnonymize();

        await handleChat();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isSubmitting) {
      e.preventDefault();
      handleSubmitChat();
    } else if (e.key === "Enter" && e.shiftKey) {
      setPrompt((prev) => prev + "\n");
    }
  };

  const handleButtonClick = () => {
    if (!isSubmitting) {
      handleSubmitChat();
    }
  };

  const handleImageGeneration = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;

    // Add a temporary entry to indicate progress
    const tempEntry = {
      originalPrompt: trimmedPrompt,
      imageResponse: "Generating image...",
      isTemporary: true, // Flag to identify temporary entries
    };
    // setConversationHistory((prevHistory) => [...prevHistory, tempEntry]);
    setConversationHistory(tempEntry);
    setHasLanded(false);
    setLoading(true);
    try {
      const response = await generateImage(trimmedPrompt, "True");
      let url = response.data[1];
      url = url.replace(/^#|#$/g, ""); // Remove leading/trailing hash symbols
      const newId = response.data[0];
      navigate(`/ai-safe/c/${newId}`);

      const newEntry = {
        originalPrompt: trimmedPrompt,
        imageResponse: url || "Image generated successfully!",
      };

      // Replace the temporary entry with the final response
      setConversationHistory((prevHistory) =>
        prevHistory.map((entry) => (entry.isTemporary ? newEntry : entry))
      );

      setPrompt(""); // Clear the prompt input
    } catch (error) {
      console.error("Error generating image:", error.message);

      // Remove the temporary entry in case of an error
      setConversationHistory((prevHistory) =>
        prevHistory.filter((entry) => !entry.isTemporary)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleToggleImageMode = () => {
    setIsImageMode((prev) => !prev);
  };

  return (
    <>
      <div
        className={`ai_safe_sidebar ${sidebarVisible ? "visible" : ""}`}
        ref={sidebarRef}
      >
        <div className="ai_safe_sidebar-content">
          <PrivacySettings
            privacySettings={privacySettings}
            handleCheckboxChange={handleCheckboxChange}
            closeSidebar={closeSidebar}
            modelLanguage={modelLanguage} // Pass the selected language
            handleLanguageChange={handleLanguageChange} // Pass handler
            handleIsChatAnonymized={handleIsChatAnonymized}
          />
        </div>
      </div>

      <div className="main_ai_safe">
        <div className="headingsdfdsf_ai_safe">
          <div className="conversation_history" ref={conversationHistoryRef}>
            {conversationHistory.originalPrompt !== "" && (
              <ConversationEntry key={1} entry={conversationHistory} id={id} />
            )}
          </div>
          {showScrollButton && (
            <button
              className="scroll_to_bottom_button"
              onClick={scrollToBottom}
            >
              <BsArrowDown />
            </button>
          )}
          {hasLanded && (
            <div className="landing_container">
              <div className="heading_ai_safe">{t("heading")}</div>
              <div className="text_ai_safe">{t("heading2")}</div>
              <img
                src={img2}
                className="ai_safe_image"
                alt="image comes here"
              />
              <div className="mid_text_ai_safe">
                {t("line1")}
                <div className="row_ai_safe">
                  <div>{t("line2")}</div>
                  <div>{t("line3")}</div>
                </div>
              </div>
            </div>
          )}

          {/* {loading && <div className="loading_message">AI is thinking...</div>} */}
          {error && (
            <div className="error_message">
              <strong>Error:</strong> {error}
            </div>
          )}
        </div>

        <div className="bottom_top_ai_safe">
          <div className="bottom_ai_safe_main d-flex flex-column gap-2">
            {(piiDetection?.Names?.length > 0 ||
              piiDetection?.Phones?.length > 0 ||
              piiDetection?.Emails?.length > 0) &&
            isAnonymized &&
            isEditing === 0 ? (
              <PromptHighlighter
                prompt={prompt}
                piiDetectionList={piiDetection}
                updatedPrompt={setPrompt}
                setPii={setPiiDetection}
              />
            ) : (
              <div className="w-100 textarea_container">
                <div
                  onClick={() => setShowFileUploadPopup(true)}
                  className="upload_button "
                >
                  <GoPaperclip size={25} />
                </div>
                <textarea
                  ref={textareaRef}
                  className="input_ai_safe"
                  placeholder="Enter your prompt here..."
                  value={prompt}
                  onChange={(e) => {
                    setPrompt(e.target.value);
                    adjustTextareaHeight();
                  }}
                  onKeyDown={handleKeyPress}
                  rows={1}
                  // style={{ overflowY: "hidden" }}
                ></textarea>

                {showFileUploadPopup && (
                  <div className="file-upload-popup">
                    <div className="popup-content">
                      <h3>Upload your file</h3>
                      {isUploading ? (
                        <div className="circular-loader">
                          {/* You can use a CSS spinner or an icon */}
                          <div className="spinner"></div> {/* CSS loader */}
                          <p>Uploading...</p>
                        </div>
                      ) : (
                        <input
                          type="file"
                          onChange={(e) => handleFileUpload(e)}
                        />
                      )}
                      <button onClick={() => setShowFileUploadPopup(false)}>
                        Close
                      </button>
                    </div>
                  </div>
                )}

                <div>
                  <div className="left_20 button_ai_safe_main2" onClick={show}>
                    <img
                      src={img1}
                      alt=""
                      height={35}
                      width={35}
                      className="img_ai_safe"
                    />
                  </div>
                  <button
                    className="button_ai_safe_main"
                    onClick={handleButtonClick}
                    disabled={loading}
                  >
                    <div className="logo_ai_safe_main">
                      <LuSendHorizonal />
                    </div>
                  </button>
                </div>
              </div>
            )}
            {/* <div className="d-flex justify-content-between">
                <label className=" font-4" style={{ fontSize: '0.8rem', color: '#FECDCA' }}>  {(piiDetection?.Names?.length === 0 && piiDetection?.Phones?.length === 0 && piiDetection?.Emails?.length === 0) && isAnonymized && isEditing === 0 && "No PII detected"}</label>
                <label className=" d-flex gap-2 justify-content-end align-items-center" style={{ color: '#b4b4b4' }} >
                  <input
                    type="checkbox"
                    checked={privacySettings.isChatAnonymized}
                    onChange={handleIsChatAnonymized}
                    className="pii-checkbox"
                  />
                  <span className=" font-4" style={{ fontSize: '0.8rem', color: '#b4b4b4' }}>Edit PII before chat.</span>
                </label>
              </div> */}
            <div className="toggle_image_mode">
              <label className="toggle-label">
                <input
                  type="checkbox"
                  checked={isImageMode}
                  onChange={handleToggleImageMode}
                  className="image-checkbox"
                />
                <span className="toggle-slider"></span>
                <span className="toggle-text">Enable Image Generation</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainAiSafe;
