export const initialFormData = {
    configSetName: '',
    configSetDescription: '',
    configSetType: '',
};

export const initialSharePointData = {
    sameConfig: true,
    sourceConfig: {
        fileSystemType: "",
        config: {
            siteName: "",
            directoryPath: "",
            clientId: "",
            tenantId: "",
            clientSecret: "",
            deleteSourceFile:false
        }
    },
    targetConfig: {
        fileSystemType: "",
        config: {
            siteName: "",
            directoryPath: "",
            clientId: "",
            tenantId: "",
            clientSecret: "",
            deleteSourceFile:false
        }
    }
};
export const initialDataBaseData = {
    sameConfig: true,
    sourceConfig: {
        databaseType: '',
        config: {
            databaseHost: '',
            databasePort: '',
            authType: 'PASSWORD', // or 'SSH_KEY' | 'NONE'

            // If PASSWORD selected
            databaseUser: '',
            databasePassword: '',

            databaseName: '',
            schemaName: '',
            backupSchema: '',

            // IF HANA selected
            databaseMode: 'SINGLE_CONTAINER', // or 'MULTIPLE_CONTAINER'
            hanaDbType: 'TENANT', // or 'TENANT'
        }
    },
    targetConfig: {
        databaseType: '',
        config: {
            databaseHost: '',
            databasePort: '',
            authType: 'PASSWORD', // or 'SSH_KEY' | 'NONE'

            // If PASSWORD selected
            databaseUser: '',
            databasePassword: '',

            databaseName: '',
            schemaName: '',
            backupSchema: '',

            // IF HANA selected
            databaseMode: 'SINGLE_CONTAINER', // or 'MULTIPLE_CONTAINER'
            hanaDbType: 'TENANT', // or 'TENANT'
        }
    },
}
export const availableConfigType = [
    { value: "DATABASE", label: 'DATABASE', disabled: false },
    { value: "FTP", label: 'FTP', disabled: false },
];
export const availableDatabaseType = [
    { value: "POSTGRES", label: 'POSTGRES', disabled: false },
    { value: "HANA", label: 'HANA', disabled: false },
    { value: "ORACLE", label: 'ORACLE', disabled: false },

]
export const availableAuthType = [
    { value: "PASSWORD", label: 'User and Password', disabled: false },
    { value: "NONE", label: 'No Auth', disabled: false },
    // { value: "SSH_KEY", label: 'SSH_KEY', disabled: true },
]
export const availableFileSystemType = [
    { value: "SHAREPOINT", label: 'SHAREPOINT', disabled: false },
    { value: "S3", label: 'S3', disabled: true },
]