// src/Settings.js
import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import "./settings.scss";
import Name_source from "./name_source";
import Admin_per from "./admin_permission";
import Manage_projects from "./manage_projects";


const Logging = lazy(() => import("./Logging"));
const Backup = lazy(() => import("./Backup"));
const PiiDetection = lazy(() => import("./PII/PiiDetection"));
const Language = lazy(() => import("./Language"));
const DataCleansing = lazy(() => import("./dataCleansing/DataCleansing"));

const Settings = () => {
  const { t } = useTranslation("setting");
  const [activeTab, setActiveTab] = useState("Logging");
  const [selectedAudit, setSelectedAudit] = useState("audit 1");
  const [selectedTechnical, setSelectedTechnical] = useState("technical 1");

  const handleTabChange = (event) => {
    const tab = event.target.innerText;
    setActiveTab(tab);
  };

  const tabNames = [
    t("Logging"),
    t("Backup & Restore"),
    t("Synch with Cloud"),
    t("PII Detection"),
    t("Cleanup"),
    t("Data Cleansing"),
    "Name Source",
    "Admin Permission",
    "Manage Projects",


  ];

  const handleSelectedTechnical = async (data) => {
    setSelectedTechnical(data);
  };

  useEffect(() => { }, [selectedAudit, selectedTechnical]);

  const activness = (tab) => {
    return tab === activeTab ? "activeTab" : "non-activeTab";
  };

  const handleSelectedAudit = async (data) => {
    setSelectedAudit(data);
  };

  const renderTabContent = useCallback(() => {
    switch (activeTab) {
      case t("Logging"):
        return (
          <Logging
            selectedAudit={selectedAudit}
            handleSelectedAudit={handleSelectedAudit}
            handleSelectedTechnical={handleSelectedTechnical}
            selectedTechnical={selectedTechnical}
          />
        );
      case t("Backup & Restore"):
        return <Backup />;
      // case t("Synch with Cloud"):
      // case t("Cleanup"):
      case t("Data Cleansing"):
        return (<DataCleansing />
        );
      case t("PII Detection"):
        return <PiiDetection />;
      case t("Languages"):
        return <Language />;
      case "Name Source":
        return <Name_source />;
      case "Admin Permission":
        return <Admin_per />
      case "Manage Projects":
        return <Manage_projects />
      default:
        return (
          <div className="font-4">
            <BsInfoCircle color="purple" />{" "}
            {t("Page under construction. Stay tuned for updates!")}
          </div>
        );
    }
  }, [activeTab, selectedAudit, selectedTechnical, t]);

  return (
    <div className="settings-container">
      <div className="header-section-maya justify-content-between align-items-center pt-4 h-25">
        <h1 className="font-5 heading1">{t("Settings")}</h1>
      </div>
      <div className="settingsNavbar d-flex pt-4" style={{ padding: "0 32px" }}>
        <ul className="d-flex flex-column font-4" style={{ width: "17%" }}>
          {tabNames.map((tabName) => (
            <li
              key={tabName}
              onClick={handleTabChange}
              className={`font-6 cursor-pointer ${activness(tabName)}`}
            >
              {tabName}
            </li>
          ))}
        </ul>
        <div className="body-section-maya setting-screen-body">
          <Suspense fallback={<div>Loading...</div>}>
            {renderTabContent()}
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Settings;
