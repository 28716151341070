import { useState, useEffect, useContext } from "react";
import { read, utils } from "xlsx";
import {
  createCollaborationGroup,
  getCollaborationGroups,
} from "../../../hooks/apis/useAppSafeApi";
import "./File.scss";
import { Modal, Button, Form } from "react-bootstrap";
import { HiOutlineUpload } from "react-icons/hi";
import { UserContext, useUserContext } from "../../../hooks/UserContext";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Select from "react-select";
import { serverAxios } from "../../../utils/AxiosUtils";
import * as XLSX from "xlsx";
import { getUtilityParam } from "../../../hooks/apis/useAppSafeApi";
import TablePro from "../../../components/newTable/TablePro";
import {
  availablePrivacyRelevance,
  formatFileSize,
  formatLabel,
  privacyRelevanceMap,
  selectDropdownStyle,
  utilityMap,
  validFileType,
} from "./FileSafeConstant.js";
import Limitation from "./Discription/Limitation.jsx";
import Filesafe_Footer from "./Discription/Filesafe_Footer.jsx";
import FileSafe_Header from "./Discription/FileSafe_Header.jsx";
import Basic_Info_Filesafe from "./Discription/Basic_Info_Filesafe.jsx";
import Job_Name_File_Safe from "./Discription/Job_Name_File_Safe.jsx";
const { REACT_APP_SITE_TITLE, REACT_APP_BASE_URL } = process.env;

const File = () => {
  const navigate = useNavigate();
  const FULL_URL = `${REACT_APP_BASE_URL}pii/generate-utility`;
  const { showToast } = useUserContext();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileHeaders, setFileHeaders] = useState([]);
  const [show, setShow] = useState(false);
  const [uploadFile, setFile] = useState(null);
  const [fileInfo, setFileInfo] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showPreconditionsDialog, setShowPreconditionsDialog] = useState(false);
  const { isLoading, setLoading } = useContext(UserContext);
  const [collapsibleContent, setCollapsibleContent] = useState({
    display: "block",
  });
  const [selectedParamsConditionRows, setSelectedParamsConditionRows] =
    useState([]);
  const [piiSets, setPiiSets] = useState([]);
  const [showAddJobName, setShowAddJobName] = useState(false);
  const [addBusinessName, setAddBusinessName] = useState(false);
  const [jobName, setJobName] = useState("");
  const [utilityParamsCondition, setUtilityParamsCondition] = useState([]);
  const [collaborationGroups, setCollaborationGroups] = useState([]);
  const [newGroup, setNewGroup] = useState({
    projectName: "",
    projectDescription: "",
    businessArea: "",
    industryArea: "",
    projectAttributes: {},
  });

  const handleRemoveAttribute = (key) => {
    const updatedAttributes = { ...newGroup.projectAttributes };
    delete updatedAttributes[key]; // Remove the selected key
    setNewGroup({ ...newGroup, projectAttributes: updatedAttributes });
  };

  const handleAddAttribute = () => {
    setNewGroup((prev) => ({
      ...prev,
      projectAttributes: {
        ...prev.projectAttributes,
        [`key${Object.keys(prev.projectAttributes).length + 1}`]: "", // Add new key-value pair
      },
    }));
  };
  const getParamAndCon = () => {
    getUtilityParam()
      .then((res) => {
        setUtilityParamsCondition(res);
      })
      .catch((err) => {
        console.log("err:: ", err.message);
      });
  };
  useEffect(() => {
    getParamAndCon();
  }, []);

  useEffect(() => {}, [fileHeaders]);

  const onFileChange = async (event) => {
    event.preventDefault();
    let oFileInfo = {};
    let files = event.target.files;
    let valid = true;

    if (!files.length) {
      showToast("No file select");
      valid = false;
    }

    const file = files[0];

    if (valid && !validFileType(file)) {
      showToast("Selected file no supported!");
      event.target.value = null;
      valid = false;
    }

    if (!valid) return;

    oFileInfo.name = file.name;
    setSelectedFile(file);
    setFile(file);
    const data = await file.arrayBuffer();
    const workBook = read(data, { sheetRows: 2 });

    const workSheet = workBook.Sheets[workBook.SheetNames[0]];
    const jsonData = utils.sheet_to_json(workSheet, {
      header: 1,
    });

    try {
      let oRange = utils.decode_range(workSheet["!fullref"]);
      oFileInfo.rows = oRange.e.r;
    } catch (e) {
      console.info(
        `Was not possible to retrieve Row Count for file type: ${file.type} `
      );
    }

    oFileInfo.size = formatFileSize(file.size);
    const tableData = jsonData[0].map((field, index) => {
      return {
        fieldName: field,
        dataType: 1,
        anonMethod: 0,
        sensitivity: 0,
        sequenceNo: index + 1,
      };
    });
    setFileInfo(oFileInfo);
    setFileHeaders(tableData);
  };

  const onDeriveDataTypes = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (uploadFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array", sheetRows: 100 });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        // const workBook = read(data, { sheetRows: 2 });
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        onPiiType(jsonData);
      };

      reader.readAsArrayBuffer(uploadFile);
    }
  };
  const onPiiType = (jsonData) => {
    const params = {
      file: jsonData,
      file_name: uploadFile.name,
    };

    serverAxios.defaultAxios
      .post(FULL_URL, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPiiSets(response.data.data);

        const metaData = response.data.data.metaData;
        const updatedRows = selectedParamsConditionRows.map((row, index) => {
          const piiResult = metaData[index];
          let matchedParam = utilityParamsCondition.find((item) => {
            const utilityParam = piiResult.utilityParameter.toLowerCase();
            const itemValue = item.value.toLowerCase();
            if (utilityParam.length >= itemValue.length) {
              return utilityParam.includes(itemValue);
            } else {
              return itemValue.includes(utilityParam);
            }
          });
          return piiResult
            ? {
                ...row,
                columnName: piiResult.fieldname,
                selectedParams: piiResult.utilityParameter,
                sensitivity: privacyRelevanceMap[piiResult.privacyRelevance],
              }
            : row;
        });
        setSelectedParamsConditionRows(updatedRows);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showToast(error.message, "fail");
        console.error("Error:", error);
        showToast(error.statusText, "fail");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (fileHeaders.length > 0) {
      const initialRows = fileHeaders.map((column) => ({
        columnName: column.fieldName,
        selectedParams: "",
        selectedCondition: [],
        conditions: [],
        sensitivity: "",
        rowIndex: column.sequenceNo - 1,
      }));
      setSelectedParamsConditionRows(initialRows);
    }
  }, [fileHeaders]);
  const handleParamsChange = (event, rowIndex) => {
    const selectedValue = event.target.value;
    const selectedObject = utilityParamsCondition.find(
      (item) => item.label === selectedValue
    );
    const updatedRows = [...selectedParamsConditionRows];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      selectedParams: selectedValue,
      conditions: selectedObject ? selectedObject.utilityParamConditions : [],
      selectedCondition: [],
    };

    setSelectedParamsConditionRows(updatedRows);
  };
  const onSensitivityChange = (columnName, selectedPrivacy) => {
    const updatedRows = selectedParamsConditionRows.map((item) => {
      if (item.columnName === columnName) {
        item.sensitivity = selectedPrivacy;
      }
      return item;
    });
    setSelectedParamsConditionRows(updatedRows);
  };
  const handleConditionChange = (selectedCondition, columnName) => {
    const updatedRows = selectedParamsConditionRows.map((item) => {
      if (item.columnName === columnName) {
        item.selectedCondition = selectedCondition;
      }
      return item;
    });
    setSelectedParamsConditionRows(updatedRows);
  };
  const onCustomFixedValuesChange = (value, columnName) => {
    const newAnonymizeFields = selectedParamsConditionRows.map((item) => {
      if (item.columnName === columnName) {
        item.customParameter = value
      }
      return item;
    });
    setSelectedParamsConditionRows(newAnonymizeFields);
  }
  const _getFormData = () => {
    let columnList = selectedParamsConditionRows.map((row) => ({
      name: row.columnName,
      utilityParam: {
        label: row.selectedParams === "" ? "NO_CHANGE" : row.selectedParams,
        value:
          row.selectedParams === ""
            ? "No Change"
            : formatLabel(row.selectedParams),
      },
      utilityParamConditions: "" ? [] : row.selectedCondition,
      privacyRelevance: row.sensitivity ? row.sensitivity : "NOT_RELEVANT",
      customParameter: row.selectedParams === "FIXED_VALUE"?(row.customParameter ? row.customParameter:"" ): null
    }));

    const formData = {
      columnList: columnList,
      projectId: selectedGroup,
      jobName: jobName
        ? jobName
        : "Job No - " + Math.floor(Math.random() * 1000),
    };

    return formData;
  };
  const onExecuteClick = async (e) => {
    let formData = new FormData();

    if (fileHeaders.length === 0) return;
    const dto = _getFormData();
    formData.append("file", selectedFile);
    formData.append(
      "dto",
      new Blob([JSON.stringify(dto)], { type: "application/json" })
    );
    let uploaded = false;
    setLoading(true);
    serverAxios.fileSafeAxios
      .post("file/anonymize-engine", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          console.error("There was an error!");
          showToast("here was an error!", "fail");
        } else {
          showToast(
            "File has been uploaded to for processing. Please visit 'History' page for status.",
            "success"
          );
          uploaded = true;
        }
      })
      .catch((oError) => {
        showToast(oError.message, "fail");
        console.error("There was an error!" + oError);
        // setError(oError.response.data);
      })
      .finally(() => {
        setLoading(false);
        if (uploaded) navigate("/history", { state: "fileSafe" });
      });
  };
  const handleJobSubmitKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmitJob();
    }
  };

  const DisplayRows = [
    {
      name: "Field Name",
      field: "columnName",
      type: "text",
      fontWeight: "600",
    },
    {
      name: "Utility Parameter",
      type: "customControl",
      cursor: "pointer",
      fnRenderCustomControl: (item) => {
        return (
          <div key={item.rowIndex}>
            <select
              style={selectDropdownStyle}
              value={item.selectedParams}
              onChange={(e) => handleParamsChange(e, item.rowIndex)}
            >
              <option key="0" value="NO_CHANGE">
                No Change
              </option>
              {utilityParamsCondition.map((method, i) => {
                return (
                  <>
                    <option key={i} value={method.label} className="">
                      {method.value}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        );
      },
    },
    {
      name: "Utility Params & Condition",
      type: "customControl",
      cursor: "pointer",
      fnRenderCustomControl: (item, index) => {
        const utilityParam = item.selectedParams;
        const availableConditions = utilityParamsCondition
          .filter((u) => {
            if (!utilityParam) return false;
            return u.label === utilityParam;
          })
          .flatMap((u) => {
            if (u.utilityParamConditions) return u.utilityParamConditions;
            return [];
          });
        return (
          <>
            {
              utilityParam === 'FIXED_VALUE' ?<>
                <input className="input-field-shadow font-4" label={''} value={item.customParameter} onChange={(e) => onCustomFixedValuesChange(e.target.value, item.columnName)} placeholder="Type..." name={'customParameter'} id={''} />
                </>:
            <Select
              className="font-4"
              options={availableConditions}
              value={item.selectedCondition}
              onChange={(selectedOption) =>
                handleConditionChange(selectedOption, item.columnName)
              }
              isMulti
              placeholder={
                availableConditions.length === 0
                  ? "No Options"
                  : item.selectedCondition.length === 0
                  ? "Use Default"
                  : "Select..."
              }
              menuPlacement="auto"
              styles={selectDropdownStyle}
              menuPortalTarget={document.body}
            />}
          </>
        );
      },
    },
    {
      name: "Privacy Relevance",
      type: "customControl",
      fnRenderCustomControl: (item) => {
        return (
          <div key={item.rowIndex}>
            <select
              style={selectDropdownStyle}
              value={item.sensitivity}
              onChange={(e) =>
                onSensitivityChange(item.columnName, e.target.value)
              }
            >
              {availablePrivacyRelevance.map((method, i) => {
                return (
                  <>
                    <option key={i} value={method.value} className="">
                      {method.label}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        );
      },
    },
  ];
  const handleShow = () => setShowAddJobName(true);
  const handleSubmitJob = () => {
    onExecuteClick();
    setShowAddJobName(false);
  };

  const handleShow_SaveAs = () => setAddBusinessName(true);
  const handleSubmitArea = async () => {
    if (Object.keys(newGroup.projectAttributes).length === 0) {
      showToast("Project attributes are not passed.");
      return;
    }

    try {
      const response = await createCollaborationGroup(newGroup);
      if (response && response.success) {
        setCollaborationGroups((prevGroups) => [...prevGroups, response]);
        showToast("New collaboration group created.", "success");
        fetchCollaborationGroups();
      } else {
        showToast("Failed to create the collaboration group.");
      }
      setAddBusinessName(false);
    } catch (error) {
      console.error("Error creating new collaboration group:", error);
      showToast(error.message);
    }
  };

  useEffect(() => {
    fetchCollaborationGroups();
  }, []);
  const fetchCollaborationGroups = async () => {
    try {
      const response = await getCollaborationGroups();
      if (Array.isArray(response)) {
        setCollaborationGroups(response);
      } else {
        console.error(
          "Failed to fetch collaboration groups:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error fetching collaboration groups:", error);
    }
  };
  const availableGroups = collaborationGroups.map((group) => ({
    value: group.id,
    label: group.projectName,
  }));

  const allOptions = [...availableGroups];

  return (
    <>
      <Helmet>
        <title>File Anonymization - {REACT_APP_SITE_TITLE} </title>
      </Helmet>
      <div className="fileContentWrapper">
        <FileSafe_Header />
        <div className="productDescription" style={collapsibleContent}>
          <Limitation
            isShow={setShowPreconditionsDialog}
            show={showPreconditionsDialog}
          />
        </div>
        <div className="fileAnonymization">
          <div className="fileDetailsWrapper">
            <div className="uploadFileDetails">
              {uploadFile && (
                <>
                  <span>
                    <b>File Name: </b> {fileInfo.name}
                  </span>
                  <span>
                    <b>File Size: </b> {fileInfo.size}
                  </span>
                  {fileInfo.rows && (
                    <span>
                      <b>No of Records: </b> {fileInfo.rows}
                    </span>
                  )}
                  {uploadFile && (
                    <span className="fileGroup">
                      <b>File Group: </b>{" "}
                      {/* {
                        
                        <select
                          className="custom-select"
                          // defaultValue={selectedGroup}
                          onChange={(e) => setSelectedGroup(e.target.value)}
                        >
                          {collaborationGroups.map((group) => {
                            return (
                              <option key={group.id} value={group.id}>
                                {group.projectName}
                              </option>
                            );
                          })}
                        </select>
                      } */}
                      <Select
                        id="collaborationGroup"
                        className="font-4"
                        options={allOptions}
                        onChange={(selectedOption) =>
                          setSelectedGroup(selectedOption.value)
                        }
                        placeholder="Select a collaboration group"
                        menuPlacement="auto"
                        styles={selectDropdownStyle}
                        menuPortalTarget={document.body}
                      />
                    </span>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="fileUploadWrapper">
            <input
              type="file"
              name="file"
              id="file"
              accept=".csv,.xlsx"
              className="inputFile"
              onChange={onFileChange}
            />
            <label htmlFor="file" className="iconss">
              <figure className="">
                <HiOutlineUpload className="fileUploadIcon" />
                <p className="font-4 text-nowrap mt-2 " style={{ margin: "-30px" }}>Choose a file</p>
              </figure>
            </label>
          </div>
        </div>
        <div
          className="fileTableDiv"
          style={
            collapsibleContent.display === "none"
              ? { height: "70vh" }
              : { height: "50vh" }
          }
        >
          {fileHeaders.length > 0 && (
            <TablePro
              columns={DisplayRows}
              data={selectedParamsConditionRows}
            />
          )}
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <Basic_Info_Filesafe show={show} setShow={setShow} />
        </div>

        {/* enter job name  modal  */}
        <Job_Name_File_Safe
          show={showAddJobName}
          setShow={setShowAddJobName}
          handleSubmitJob={handleSubmitJob}
          setJobName={setJobName}
          jobName={jobName}
          keypress={handleJobSubmitKeypress}
        />

        <Modal
          show={addBusinessName}
          centered
          onHide={() => setAddBusinessName(false)}
          backdrop="static"
          keyboard={false}
          className="custom-modal"
        >
          <Modal.Header>
            <Modal.Title>Create New Collaboration Group</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              {/* Project Name */}
              <Form.Group className="mb-3" controlId="projectName">
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  name="projectName"
                  value={newGroup.projectName}
                  onChange={(e) =>
                    setNewGroup({ ...newGroup, projectName: e.target.value })
                  }
                  placeholder="Enter project name"
                  className="form-control"
                  required
                />
              </Form.Group>

              {/* Project Description */}
              <Form.Group className="mb-3" controlId="projectDescription">
                <Form.Label>Project Description</Form.Label>
                <Form.Control
                  type="text"
                  name="projectDescription"
                  value={newGroup.projectDescription}
                  onChange={(e) =>
                    setNewGroup({
                      ...newGroup,
                      projectDescription: e.target.value,
                    })
                  }
                  placeholder="Enter project description"
                  className="form-control"
                  required
                />
              </Form.Group>

              {/* Business Area */}
              <Form.Group className="mb-3" controlId="businessArea">
                <Form.Label>Business Area</Form.Label>
                <Form.Control
                  type="text"
                  name="businessArea"
                  value={newGroup.businessArea}
                  onChange={(e) =>
                    setNewGroup({ ...newGroup, businessArea: e.target.value })
                  }
                  placeholder="Enter business area"
                  className="form-control"
                  required
                />
              </Form.Group>

              {/* Industry Area */}
              <Form.Group className="mb-3" controlId="industryArea">
                <Form.Label>Industry Area</Form.Label>
                <Form.Control
                  type="text"
                  name="industryArea"
                  value={newGroup.industryArea}
                  onChange={(e) =>
                    setNewGroup({ ...newGroup, industryArea: e.target.value })
                  }
                  placeholder="Enter industry area"
                  className="form-control"
                  required
                />
              </Form.Group>

              {/* Dynamic Project Attributes */}
              <Form.Group className="mb-3" controlId="projectAttributes">
                <Form.Label>Project Attributes</Form.Label>
                {Object.entries(newGroup.projectAttributes).map(
                  ([key, value], index) => (
                    <div key={index} className="attribute-pair">
                      <input
                        type="text"
                        placeholder="Key"
                        value={key}
                        onChange={(e) => {
                          const updatedAttributes = {
                            ...newGroup.projectAttributes,
                          };
                          delete updatedAttributes[key]; // Remove the old key
                          updatedAttributes[e.target.value] = value; // Set the new key
                          setNewGroup({
                            ...newGroup,
                            projectAttributes: updatedAttributes,
                          });
                        }}
                        className="form-control attribute-key"
                      />
                      <input
                        type="text"
                        placeholder="Value"
                        value={value}
                        onChange={(e) => {
                          const updatedAttributes = {
                            ...newGroup.projectAttributes,
                          };
                          updatedAttributes[key] = e.target.value; // Update value for the key
                          setNewGroup({
                            ...newGroup,
                            projectAttributes: updatedAttributes,
                          });
                        }}
                        className="form-control attribute-value"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveAttribute(key)}
                        className="remove-attribute"
                      >
                        Remove
                      </button>
                    </div>
                  )
                )}
                <button
                  type="button"
                  onClick={handleAddAttribute}
                  className="add-attribute"
                >
                  Add Attribute
                </button>
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setAddBusinessName(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmitArea}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      {fileHeaders.length > 0 && (
        <Filesafe_Footer
          isLoading={isLoading}
          createGroup={handleShow_SaveAs}
          onDeriveDataTypes={onDeriveDataTypes}
          executeJob={handleShow}
        />
      )}
    </>
  );
};
export default File;
